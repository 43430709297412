<template>
    <div class="page1">
        <EForm
                :formColumns="formColumns"
                :rowSize="4"
                :optionsBtn="true"
                :actionBtn="false"
                :formData="searchForm"
                ref="form"
                @onSearch="onSearch"
                :searchFlag="true"
                @getChange="changeSelect"
                url="tenantInfoExport"
                fileName="商户信息"
                :exportData="searchForm"
        >
        </EForm>
        <EButton type="primary" size="small" @click="goTo('ShopBasicInfoAdd')">增加</EButton>
        <ETable :tableTitle="tableTitle"
                :tableData="tableData"
                :needPagination="true"
                :count="count"
                @changePage="changePage"
                @changeSize="changeSize"
                :page="searchForm.current"
                :page_size="searchForm.size"
        >
            <el-table-column
                    fixed="right"
                    label="操作"
                    width="200px"
            >
                <template slot-scope="scope">
                    <div>
                        <EButton type="text" @click="goTo('ShopBasicInfoDetail',{id:scope.row.tenantBasicInformationId})">
                            详情
                        </EButton>
<!--                        <EButton type="text" @click="goTo('ShopLicenceAdd',{id:scope.row.tenantBasicInformationId})">-->
<!--                            添加证照-->
<!--                        </EButton>-->
                        <EButton type="text" @click="remove(scope.row)" :disabled="scope.row.isRent>0">
                            删除
                        </EButton>
                      <EButton type="text" @click="goTo('SignContract',{id:scope.row.tenantBasicInformationId})">
                        签合同
                      </EButton>
                    </div>
                </template>
            </el-table-column>
        </ETable>
        <EDialog :dialogVisible="dialogVisible" title="添加" @handleClose="dialogVisible=false"
                 width="30%">
            <EForm
                    :formColumns="dialogFormColumns"
                    :rowSize="1"
                    :optionsBtn="false"
                    :actionBtn="true"
                    :formData="dialogForm"
                    ref="form"
                    @onSearch="onSearch"
                    :searchFlag="false"
                    @handleClick="saveData"
                    @handleClose="cancelDialog"
                    :labelPosition="labelPosition"
                    :labelWidth="labelWidth"
                    :formRules="formRules"
                    @changeRadio="changeRadio"
            >
            </EForm>
        </EDialog>
    </div>
</template>

<script>
  import ETable from '@/components/ETable'
  import Http from "@/service/http";
  import EDialog from '@/components/EDialog'
  import EButton from '@/components/EButton'
  import EForm from '@/components/EForm'
  import vxRule from "@/assets/js/formValidate";
  import {otherMixin} from "@/components/mixin";

  export default {
    name: 'Login',
      mixins: [otherMixin],
    data() {
      return {
        tableTitle: [
          {
            label: '商户编号',
            prop: 'tenantCode'
          },
          {
            label: '商户名称',
            prop: 'tenantName'
          },
          {
            label: '商户类型',
            prop: 'tenantTypeCn'
          },
          {
            label: '负责人',
            prop: 'signPerson',
          },
          {
            label: '身份证号',
            prop: 'idCard',
          },
          {
            label: '联系电话',
            prop: 'phone',
          },
          {
            label: '录入人',
            prop: 'createUserCn',
          },
          {
            label: '录入时间',
            prop: 'createTime',
          },
          {
            label: '最近一次修改人',
            prop: 'updateUserCn',
          },
          {
            label: '修改时间',
            prop: 'updateTime',
          },
        ],
        tableData: [
        ],
        searchForm: {
          tenantCode:null,
          tenantType: null,
          createUser: null,
          updateUser: null,
          areaId:null,
          boothCode:null,
          createTimeStart:null,
          createTimeEnd:null,
          placeId:null,
          signPerson:null,
          current: 1,
          size: 10,
        },
        count: null,
        formColumns: [
          {
            title: '商户编号',
            type: 'text',
            property: 'tenantCode',
            show: true
          },
          {
            title: '商户类型',
            type: 'select',
            property: 'tenantType',
            show: true,
            options: [
              {
                label:'全部',
                value:''
              },
               {
                label:'个体',
                value:1
              },
              {
                label:'公司',
                value:2
              },
              {
                label:'其他',
                value:3
              },
            ]
          },
          {
            title: '录入人',
            type: 'text',
            property: 'createUser',
            show: true
          },
          {
            title: '修改人',
            type: 'text',
            property: 'updateUser',
            show: true
          },
          {
            title: '区域',
            type: 'select',
            property: 'areaId',
            show: true,
            options:[]
          },
          {
            title: '摊位号',
            type: 'text',
            property: 'boothCode',
            show: true
          },
          {
            title: '录入开始时间',
            type: 'datePicker',
            property: 'createTimeStart',
            show: true
          },
          {
            title: '位置',
            type: 'select',
            property: 'placeId',
            show: true,
            options:[]
          },
          {
            title: '负责人',
            type: 'text',
            property: 'signPerson',
            show: true,
          },

          {
            title: '录入截止时间',
            type: 'datePicker',
            property: 'createTimeEnd',
            show: true
          },
          {
            title: '身份证号',
            type: 'text',
            property: 'idCard',
            show: true
          },
        ],
        dialogFormColumns: [
          {
            title: '推送区域',
            type: 'pushCascader',
            property: 'area_code',
            placeHolder: '最多可录入20字',
            show: true,
          },
          {
            title: '标题',
            type: 'text',
            property: 'title',
            placeholder: '最多可录入20字',
            show: true
          },
          {
            title: '内容',
            type: 'textarea',
            property: 'content',
            placeholder: '最多可录入75字',
            show: true
          },
          {
            title: '用户',
            type: 'radio',
            property: 'user_type',
            show: true,
            options:[
              {
                value: '1',
                label: '工人'
              },
              {
                value: '2',
                label: '雇主'
              },
            ]//identity_type.splice(1)
          },
          {
            title: '跳转位置',
            type: 'cascader',
            property: 'position_id',
            placeholder: '最多可录入20字',
            show: true,
            options:[]
          },
          {
            title: '推送时间',
            type: 'datetime',
            property: 'push_time',
            placeholder: '最多可录入20字',
            show: true,
          },
          {
            title: '状态',
            type: 'radio',
            property: 'status',
            placeholder: '最多可录入20字',
            show: true,
            options:[
              {
                value: '1',
                label: '启用'
              },
              {
                value: '0',
                label: '禁用'
              }
            ]//status.splice(1)
          },
          {
            type:'actionBtn',
            show:true
          }
        ],
        exportData:{},
        dialogVisible:false,
        labelPosition:'right',
        labelWidth:'100px',
        formRules:{
          area_code: vxRule(true, '',"change", "推送区域不能为空"),
          title: [vxRule(true, '',"blur", "标题不能为空"),
            vxRule(true, val=>{
              return {result: val.length<=20, errMsg: "最多可录入20字"}})
          ],
          user_type: vxRule(true, '',"change", "用户不能为空"),
          content: [vxRule(true, '',"blur", "内容不能为空"),
            vxRule(true, val=>{
              return {result: val.toString().length<=75, errMsg: "最多可录入75字"}})],
          position_id: [vxRule(true, '',"change", "跳转位置不能为空")],
          status: vxRule(true, '',"change", "用户不能为空"),
        },
        dialogForm:{
          area_code:'',
          id:'',
          title:'',
          content:'',
          user_type:'1',
          position_id:'',
          push_time:'',
          status:'0'
        },
        options:[]
      }
    },
    watch: {},
    created() {
      this.getAreaLabel()
      this.getData()
    },
    components: {ETable, EDialog, EButton, EForm},
    beforeMount() {

    },
    methods: {
      changeSelect(prop){
        if(prop=='areaId'){
          this.getPlaceLabel()
        }
      },
      remove (row) {
        this.$messageBox.confirm('确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.getBasicInfoRemove(row.tenantBasicInformationId)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      async getBasicInfoRemove(ids){
        let res=await Http.getBasicInfoRemove({ids})
        if(res.code==200){
          this.$message.success(res.msg)
          this.getData()
        }
      },
      changeRadio(){
        this.getData()
        this.dialogForm.position_id=''
      },
      saveData(){
        this.$refs['form'].$refs['form'].validate((valid, object) => {
          if (valid && !this.error) {
            this.addBtn()
          } else {
            return false;
          }
        });
      },
      async addBtn(){
        let {
          id,
          area_code,
          title,
          content,
          user_type,
          status,
          position_id,
          push_time
        }=this.dialogForm
        let res=await Http.addOrEditAppPushMessage({
          id,
          area_code:area_code?area_code[area_code.length-1]:'',
          title,
          content,
          user_type,
          status,
          position_id:position_id.toString(),
          push_time
        })
        if(res.flag==1){
          this.$message.success(res.msg)
          this.getData()
          this.cancelDialog()
          this.getData()
        }
      },
      cancelDialog(){
        this.dialogVisible=false
        this.dialogForm={
          area_code:'',
          id:'',
          title:'',
          content:'',
          user_type:'1',
          position_id:'',
          push_time:'',
          status:'0'
        }
      },
      showDialog(){
        this.dialogVisible=true
      },
      start(){

      },
      onSearch() {
        this.searchForm.current = 1
        this.getData()
      },
      changePage(page) {
        this.searchForm.current = page
        this.getData()
      },
      changeSize(size){
        this.searchForm.size = size
        this.getData()
      },
      async getData(){
        let res = await Http.getBasicInfoList(this.searchForm)
        if(res.code == 200){
          this.tableData = res.data.records
          this.count=res.data.total
        }
      },
      //获取位置下拉
      async getPlaceLabel(){
        let res = await Http.getPlaceLabel({
          areaId:this.searchForm.areaId
        })
        if (res.code == 200) {
          this.formColumns[7].options = res.data
        }
      },
      //获取区域下拉
      async getAreaLabel(){
        let res = await Http.getAreaLabel({
          status:null
        })
        if (res.code == 200) {
          this.formColumns[4].options = res.data
        }
      },
    }
  }
</script>

<style lang="scss">

</style>
